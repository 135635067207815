








































































































































import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import OrderProvider from '@/resources/order.provider'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import ExchangeCartItem from '@/components/desktop-pos/exchange-cart-item.vue'
import ExchangeScanProduct from '@/components/desktop-pos/exchange-scan-product.vue'
import ModalProductAddtoCart from '../exchange-order/components/modal-product-list.vue'

const orderService = new OrderProvider()

@Component({
  components: { ModalProductAddtoCart, DesktopPosNavbar, PriceSummary, ExchangeCartItem, ExchangeScanProduct },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      selectPos: (state: any) => state.Store.selectPos,
      oldNet: (state: any) => state.ExchangeOrder.oldNet,
      bill: (state: any) => state.ExchangeOrder.bill
    }),
    ...mapGetters({
      calculatedItems: 'ExchangeOrder/calculatedItems',
      tel: 'ExchangeOrder/tel',
      net: 'ExchangeOrder/net',
      payMore: 'ExchangeOrder/desktopPayMore',
      discount: 'ExchangeOrder/discount',
      fullPrice: 'ExchangeOrder/fullPrice',
      totalPrice: 'ExchangeOrder/totalPrice',
      promotionCode: 'ExchangeOrder/promotionCode'
    })
  },
  methods: {
    ...mapActions({
      setItems: 'ExchangeOrder/setItems',
      setBill: 'ExchangeOrder/setBill',
      setDiscount: 'ExchangeOrder/setDiscount',
      setPromotionCode: 'ExchangeOrder/setPromotionCode',
      validateCode: 'ExchangeOrder/validateCode',
      clearDiscount: 'ExchangeOrder/clearDiscount',
      removeItems: 'ExchangeOrder/removeItems',
      setReturnItems: 'ExchangeOrder/setReturnItems',
      setOldNet: 'ExchangeOrder/setOldNet',
      addToCart: 'ExchangeOrder/addToCart'
    })
  }
})
export default class ExchangeCart extends Vue {
  setBill!: any

  bill!: any

  oldNet!: any

  showFullPriceFormat!: any

  totalPrice!: any

  setReturnItems!: any

  fullPrice!: any

  tel!: any

  net!: any

  setItems!: any

  validateCode!: any

  setOldNet!: any

  loading = false

  loadingApplyBtn = false

  clearDiscount!: any

  discount!: any

  setDiscount!: any

  payMore!: any

  code = ''

  setPromotionCode!: any

  promotionCode!: any

  selected: any[] = []

  order: any = {}

  removeItems!: any

  oldTotalPrice = 0

  calculatedItems!: any

  modalAddToCart = false

  selectBranch!: any

  selectPos!: any

  addToCart!: any

  get isDisableApplyBtn (): boolean {
    if (this.order?.member?.tel && this.order?.member?.tel === null) {
      return true
    }
    if (this.promotionCode != null) {
      return true
    }
    return false
  }

  get cartAmount (): number {
    return this.selected.length
  }

  removeSku (skuUniqueId: string, operation: string): void {
    this.removeItems({ skuUniqueId, operation })
  }

  async validDateCodeHandle (): Promise<void> {
    this.loadingApplyBtn = true
    const result = await this.validateCode(this.code.toUpperCase())
    if (result.success) {
      this.$buefy.dialog.alert({
        message: 'This code can be use.',
        type: 'is-success',
        hasIcon: true,
        icon: 'check-circle-outline',
        iconPack: 'mdi'
      })
    } else {
      this.$buefy.dialog.alert({
        type: 'is-danger',
        hasIcon: true,
        icon: 'close-circle-outline',
        iconPack: 'mdi',
        message: `<span>${result.message}</span>`
      })
    }
    this.loadingApplyBtn = false
  }

  checkOut (): void {
    this.setItems(this.selected)
    this.setBill(this.order)
    if (this.selected.length === this.order.skus.length) {
      const promo = this.order.promotionCode.code ? this.order.promotionCode : null
      this.setPromotionCode(promo)
      this.setDiscount(promo !== null ? this.order.discount : 0)
    }
    this.$router.push({ name: 'ClickCollectCart' })
  }

  headers = [
    { label: 'Product List', field: 'productInfo' },
    { label: 'Qty', field: 'amount' },
    { label: 'Price', field: 'price' }
  ]

  loadingTrigger (val: boolean): void {
    this.loading = val
  }

  private spiltOrderItem (): any[] {
    const result: any[] = []
    const allItems = []
    for (const order of this.order.subOrders) {
      const items = order.skus.map((sk: any) => ({
        ...sk,
        warehouse: order.warehouse
      }))
      allItems.push(...items)
    }
    allItems.forEach((element: any) => {
      for (let i = 0; i < element.amount; i++) {
        result.push({
          ...element,
          operation: 'old_item',
          price: element.price / element.amount,
          fullPrice: element.fullPrice / element.amount,
          amount: 1
        })
      }
    })
    return result
  }

  async mounted (): Promise<void> {
    if (this.bill) {
      this.order = this.bill
      this.oldTotalPrice = this.oldNet
      const promo = this.order?.promotionCode?.code ? this.order.promotionCode : null
      this.code = promo ? promo.code : ''
    } else {
      const { data } = await orderService.getOrderById(this.orderId)
      this.order = data
      this.oldTotalPrice = +data.net
      this.setOldNet(this.oldTotalPrice)
      data.skus = this.spiltOrderItem()

      this.setItems(data.skus)
      this.setBill(this.order)
      this.setReturnItems([])
      const promo = this.order?.promotionCode?.code ? this.order.promotionCode : null
      this.code = promo ? promo.code : ''
      this.setPromotionCode(promo)
      this.setDiscount(promo !== null ? this.order.discount : 0)
    }
  }

  get warehouseName (): string {
    if (!this.selectBranch || this.selectBranch === null) {
      return ''
    }
    return this.selectBranch.warehouse.name
  }

  get orderId (): string {
    return this.$route.params.orderId ? this.$route.params.orderId : ''
  }

  get groupedItems (): any[] {
    return this.calculatedItems.reduce((arr: any[], v: any) => {
      const index = arr.findIndex((i: any) => i.id === v.id)

      if (index === -1) {
        return [
          ...arr,
          {
            ...v,
            quantity: 1,
            sumPrice: v.price
          }
        ]
      }

      const tmpArr = [...arr]
      tmpArr[index].quantity += 1
      tmpArr[index].sumPrice += v.price
      return tmpArr
    }, [])
  }

  get sumAmount (): string {
    return this.calculatedItems.length ? `${this.calculatedItems.length}` : '-'
  }

  gotoPaymentPage (): void {
    this.$router.push({
      name: 'PaymentChannel', query: { type: 'exchange-order', orderId: this.orderId }
    })
  }

  get previousPaid (): number {
    if (!this.order) return 0
    return this.order.payments.reduce((acc: any, payment: any) => acc + payment.payAmount, 0)
  }
}

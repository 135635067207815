
































































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class PriceSummary extends Vue {
  @Prop()
  sumAmount!: number

  @Prop()
  net!: number

  @Prop()
  discount!: number

  @Prop()
  fullPrice!: number

  @Prop()
  totalPrice!: number

  @Prop()
  oldTotalPrice!: number

  @Prop()
  payMore!: number

  showFullPriceFormat!: any
}

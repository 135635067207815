





import { Component, Vue } from 'vue-property-decorator'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductProvider from '@/resources/product.provider'
import { mapProduct } from '@/utils/product.utils'
import * as onScan from 'onscan.js'

const ProductService = new ProductProvider()

@Component({
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch
    }),
    ...mapGetters({
      cartAmount: 'ExchangeOrder/cartAmount'
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'ExchangeOrder/addToCart'
    })
  }
})
export default class ExchangeScanProduct extends Vue {
  loading = false

  addToCart!: any;

  selectBranch!: any;

  products = []

  keyCodeMapperCharacter: any = {
    48: ['0', ')'],
    49: ['1', '!'],
    50: ['2', '@'],
    51: ['3', '#'],
    52: ['4', '$'],
    53: ['5', '%'],
    54: ['6', '^'],
    55: ['7', '&'],
    56: ['8', '*'],
    57: ['9', '('],
    65: ['a', 'A'],
    66: ['b', 'B'],
    67: ['c', 'C'],
    68: ['d', 'D'],
    69: ['e', 'E'],
    70: ['f', 'F'],
    71: ['g', 'G'],
    72: ['h', 'H'],
    73: ['i', 'I'],
    74: ['j', 'J'],
    75: ['k', 'K'],
    76: ['l', 'L'],
    77: ['m', 'M'],
    78: ['n', 'N'],
    79: ['o', 'O'],
    80: ['p', 'P'],
    81: ['q', 'Q'],
    82: ['r', 'R'],
    83: ['s', 'S'],
    84: ['t', 'T'],
    85: ['u', 'U'],
    86: ['v', 'V'],
    87: ['w', 'W'],
    88: ['x', 'X'],
    89: ['y', 'Y'],
    90: ['z', 'Z'],
    186: [';', ':'],
    187: ['=', '+'],
    188: [',', '<'],
    189: ['-', '_'],
    190: ['.', '>'],
    191: ['/', '?'],
    192: ['`', '~'],
    219: ['[', '{'],
    220: ['\\', '|'],
    221: [']', '}'],
    222: ['\'', '"']
  }

  mounted (): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    onScan.attachTo(document, {
        suffixKeyCodes: [13],
        reactToPaste: true,
        onScan (sCode) {
          const patternUrl = /^(https:\/\/www\.gentlewomanonline\.com\/|https:\/\/gentlewomanonline\.com\/|https:\/\/www\.matter-makers\.com\/|https:\/\/matter-makers\.com\/)/
          if (patternUrl.test(sCode)) {
            const splitCodeFromUrl = sCode.split('/')
            const code = splitCodeFromUrl[splitCodeFromUrl.length - 1]
            if (code) _this.searchProduct(code)
          }
        },
        keyCodeMapper (oEvent: any) {
          if (_this.keyCodeMapperCharacter[+oEvent.keyCode] !== undefined) {
            return _this.keyCodeMapperCharacter[+oEvent.keyCode][+oEvent.shiftKey]
          }
          return onScan.decodeKeyEvent(oEvent)
        }
    })
  }

  addItem (item: any): void {
    this.addToCart({ ...item, operation: 'new_item' })
  }

  async searchProduct (search: string): Promise<void> {
    this.loading = true
    this.$emit('loadingTrigger', true)
    const { data: { results } } = await ProductService.getProduct({ search })
      this.products = mapProduct(results, this.selectBranch, search)
    if (this.products.length === 0) {
      this.$buefy.toast.open({ message: 'Product not found', type: 'is-danger' })
    } else {
        this.addItem(this.products[0])
    }
    this.$emit('loadingTrigger', false)
    this.loading = false
  }

  // eslint-disable-next-line class-methods-use-this
  beforeDestroy (): void {
    onScan.detachFrom(document)
  }
}
